import React, { Fragment, useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import {
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  Button,
  LinearProgress
} from '@material-ui/core';

import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';

import { NavLink as RouterLink } from 'react-router-dom';

import TextField from 'components/TextField';
import { validate, hasErrors, validateAll, required, email } from 'components/Validators';
import { useApi } from 'api';
import { login } from 'api/Cuenta';
import { useCuenta } from 'components/CuentaProvider';
import { useApp } from 'components/AppDesktop';


const fieldsValidators = {
  'username': [required, email],
  'password': [required],
}

const initialData = {
  username: '',
  password: '',
  rememberme: true,
}

const defaultErrors = {
  username: '',
  password: '',
}

const isProd = process.env.NODE_ENV === 'production';

export default function CardLogin() {

  const history = useHistory();

  const [data, setData] = useState(initialData);
  const [errors, setErrors] = useState(defaultErrors);
  const { notificarError } = useApp();
  const { actualizaCuenta } = useCuenta();

  const [{ data: loginData, loading: loadingLogin, error: errorLogin }, apiLogin] = useApi(null, { defaultError: false });

  useEffect(() => {
    if (loginData) {
      actualizaCuenta({
        id: loginData.id,
        logged: true
      });
      history.replace("/dashboard"); //Garantizamos ir al home
      if (isProd) {

      }
    }
  }, [loginData]);

  useEffect(() => {
    if (errorLogin) {
      if (errorLogin.message === "Network Error") {
        notificarError("Servicio no disponible");
      } else {
        setErrors({
          username: errorLogin.response.data.mensaje
        })
      }
    }
  }, [errorLogin]);

  const handleChangeValue = name => event => {
    const value = (name === 'rememberme') ? event.target.checked : event.target.value;
    const error = validate(value, fieldsValidators[name]);

    setData({
      ...data,
      [name]: value,
    })
    setErrors({
      ...errors,
      [name]: error,
    })
  }

  const handleOnBlur = name => event => {
    const value = event.target.value;
    const error = validate(value, fieldsValidators[name]);
    setErrors({
      ...errors,
      [name]: error,
    })
  }

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = () => {
    const errors = validateAll(data, fieldsValidators);
    const isValid = !hasErrors(errors)

    if (isValid) {
      setErrors(defaultErrors);
      apiLogin(login(data.username, data.password, data.rememberme));
    }
  }

  return (
    <Fragment>
      <Card className="m-0 w-100 p-0 border-0">
        {/* <div className="card-header d-block p-3 mx-2 mb-0 mt-2 rounded border-0">
          <div className="text-muted text-center mb-3">
            <span>Sign in with</span>
          </div>
          <div className="text-center">
            <Button
              variant="outlined"
              className="mr-2 text-google">
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fab', 'google']} />
              </span>
              <span className="btn-wrapper--label">
                Google
              </span>
            </Button>
            <Button
              variant="outlined"
              className="mr-2 text-facebook">
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fab', 'facebook']} />
              </span>
              <span className="btn-wrapper--label">
                Facebook
              </span>
            </Button>
            <Button
              variant="outlined"
              className="ml-2 text-twitter">
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fab', 'twitter']} />
              </span>
              <span className="btn-wrapper--label">
                Twitter
              </span>
            </Button>
          </div>
        </div> */}
        <CardContent className="p-3">
          <div className="text-center text-black-50 mb-4">
            <span>Captura tu correo electrónico y contraseña</span>
            {loadingLogin === true &&
              <LinearProgress />
            }
          </div>
          <form className="px-5">
            <TextField
              fullWidth
              field="username"
              label="Correo electrónico"
              type="email"
              // autoFocus
              disabled={loadingLogin === true}
              value={data.username}
              onKeyPress={handleKeyPress}
              onChange={handleChangeValue('username')}
              onBlur={handleOnBlur('username')}
              errors={errors}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineTwoToneIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              field="password"
              label="Contraseña"
              type="password"
              disabled={loadingLogin === true}
              value={data.password}
              onKeyPress={handleKeyPress}
              onChange={handleChangeValue('password')}
              onBlur={handleOnBlur('password')}
              errors={errors}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockTwoToneIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div className="w-100">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.rememberme}
                    onChange={handleChangeValue('rememberme')}
                    disabled={loadingLogin === true}
                    value="rememberme"
                    color="primary"
                  />
                }
                label="Recuérdame"
              />
            </div>
            <div className="text-center">
              <Button
                color="primary"
                variant="contained"
                size="large"
                className="my-2"
                disabled={loadingLogin === true}
                onClick={handleLogin}>
                Acceder
            </Button>
            </div>
            <div className="text-center mt-4">
              <div className="text-center text-black-50 mb-4">
                <span>¿Olvidaste tu contraseña?</span>
              </div>
              <Button
                size="large"
                color="primary"
                disabled={loadingLogin === true}
                component={RouterLink}
                to="/restablecepassword">
                <span className="btn-wrapper--label">Restablecer contraseña</span>
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </Fragment >
  )
}