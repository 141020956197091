import React, { Fragment } from 'react';

import {
  Grid,
  Container,
  Tooltip,
  Fab,
  Link,
} from '@material-ui/core';

import { NavLink as RouterLink } from 'react-router-dom';
import logoLogin from '../../assets/images/escolastia/logo-letras-negro-medium.png';

// import { NavLink as RouterLink } from 'react-router-dom';
import LoginCard from './LoginCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LivePreviewExample = () => {

  return (
    <Fragment>
      <div className="app-wrapper min-vh-100">
        <div className="app-main flex-column">
          {/* <Button
            size="large"
            variant="outlined"
            color="primary"
            component={RouterLink}
            className="btn-go-back"
            to="/DashboardDefault">
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon icon={['fas', 'arrow-left']} />
            </span>
            <span className="btn-wrapper--label">Back to dashboard</span>
          </Button> */}
          <Tooltip title="Regresar a inicio">
            <Fab
              color="primary"
              className="btn-go-back"
              component={Link}
              href="https://www.escolastia.com">
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'arrow-left']} />
              </span>
            </Fab >
          </Tooltip>
          <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5">
                  <Container maxWidth="lg">
                    <Grid container spacing={5} className="justify-content-center">
                      <Grid
                        item
                        xs={12}
                        lg={7}
                        className="d-flex flex-column align-items-center">
                        <Container
                          // component={RouterLink}
                          // to="/home"
                          maxWidth="sm"
                          className="d-flex justify-content-center">
                          <img alt="logo escolastia" src={logoLogin} />
                        </Container>
                        <span className="w-100 text-left text-md-center pb-4">
                          {/* <h1 className="display-3 text-xl-left text-center mb-3 font-weight-bold">
                            Login to your account
                          </h1> */}
                          <p className="font-size-lg text-center mb-4 mt-4 text-black-50">
                            Para acceder al administrador debes iniciar sesión
                          </p>
                        </span>
                        <LoginCard />
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default LivePreviewExample;
